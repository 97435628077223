import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ExternalLink from '../../../components/ExternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/icon/accessibility",
  "title": "Icon - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`For icons that do not have a visual text label but are important must be given a text alternative using the WAI-ARIA definition `}<inlineCode parentName="li">{`aria-label`}</inlineCode>{` or `}<inlineCode parentName="li">{`aria-labelledby`}</inlineCode>{`. See `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#text-alternatives" mdxType="ExternalLink">{`WCAG 2.1 Text Alternatives guideline`}</ExternalLink>{` for more information.`}</li>
      <li parentName="ul">{`If the icon has a visual text label associated with it, the icon can be hidden from assistive technologies with `}<inlineCode parentName="li">{`aria-hidden="true"`}</inlineCode>{`. It prevents screen readers repeating the same information twice (visual text followed by the icon description).`}</li>
      <li parentName="ul">{`If the icon is only decorative, it should always be hidden from assistive technologies with the `}<inlineCode parentName="li">{`aria-hidden="true"`}</inlineCode>{` attribute.`}</li>
      <li parentName="ul">{`The colour contrast between the icon and its background must comply with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#contrast-minimum" mdxType="ExternalLink">{`AA Level contrast ratios`}</ExternalLink>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      